<template>
  <DsSection :theme="theme">
    <div class="ds-grid ds-md-justify-content-space-between">
      <div class="ds-grid__col ds-grid__col--12 ds-margin-bottom-24"
        :class="{ 'ds-md-grid__col--5': !oneColumnLayout, 'ds-md-grid__col--6 ds-md-grid__col--offset-3': oneColumnLayout }">
        <h2 class="ds-heading ds-margin-bottom-24" :class="{ 'ds-text-align-center': oneColumnLayout }">
          {{ title }}
        </h2>
        <RichText v-if="text" :data="text" table-text-size="medium" class="newsletter-form-text ds-text"
          :class="{ 'ds-text-align-center': oneColumnLayout }" />
        <DsLink v-if="link" :content="link.title" type="button" variant="secondary" :url="link.url"
          class="ds-margin-top-24" />
      </div>
      <div class="ds-grid__col ds-grid__col--12"
        :class="{ 'ds-md-grid__col--5': !oneColumnLayout, 'ds-md-grid__col--6 ds-md-grid__col--offset-3 ds-md-padding-0': oneColumnLayout }">
        <h3 v-if="formTitle" class="ds-heading-xsmall ds-margin-bottom-24">
          {{ formTitle }}
        </h3>
        <DsPlaceholderLoader v-if="formFetchStatus === 'pending'" theme="grey" />
        <DsForm v-if="formFetchStatus !== 'pending' && formFields.length > 0" :fields="formFields"
          :submit-state="formSubmitState" :submit-label="formLabels.formSubmitLabelSubscribe"
          :submit-success-label="formLabels.formSubmitStateSuccessMessage"
          :submit-fail-label="formLabels.formSubmitStateFailMessage" @submit="onFormSubmit"
          @change.once="trackFormInteraction" />
        <RichText v-if="formLabels.formDisclaimerMessage" :data="formLabels.formDisclaimerMessage"
          class="ds-margin-top-12 ds-text-align-center" />
      </div>
    </div>
  </DsSection>
</template>
<script setup>
import { DsSection, DsLink, DsForm, DsPlaceholderLoader } from 'awds'
import { defaultFormProps, useForms } from '../composables/useForms'

const props = defineProps({
  ...defaultFormProps,
  title: { type: String, required: true },
  text: { type: Object, default: null },
  formTitle: { type: String, default: '' },
  link: { type: Object, default: null },
  oneColumnLayout: { type: Boolean, default: false },
  theme: { type: String, default: 'grey' }
})

const emit = defineEmits(['success'])

const { formFields, FORM_SUBMIT_STATE, formId, formSubmitState, formFetchStatus, formLabels, formSubmit, trackFormInteraction } = useForms(emit)

const onFormSubmit = (data) => {
  if (data.type) {
    // This is a normal event, discard
    return
  }
  formSubmit(data)
}

watch(() => props.formId, () => {
  formId.value = props.formId
}, { immediate: true })

</script>

<style scoped lang="scss">
@import 'awds/foundation-vars.scss';
@import 'awds/foundation-mixins.scss';

.newsletter-form-text :deep() {
  .ds-table {
    margin-top: $spacing-0;

    @include screen('medium') {
      margin-top: $spacing-48;
    }
  }

  .ds-table__row {
    align-items: flex-start;
    background-color: $color-grey-100;
    border-bottom: 0;
    padding: 0;
  }

  p {
    margin-top: 0;
  }
}
</style>
